import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck } from "react-icons/md";
import { Input, Button } from "react-daisyui";
import {
  useProductStore,
  ProductStore,
  ProductStoreContext,
} from "./store/ProductStore";
import { observer } from "mobx-react";

const EditProductManagement = observer(() => {
  const { id } = useParams();
  const store = new ProductStore({
    isloading: false,
    editloading: true,
    id: id,
  });
  return (
    <ProductStoreContext.Provider value={store}>
      <Screen />
    </ProductStoreContext.Provider>
  );
});

export default EditProductManagement;

const Screen = observer(() => {
  const { id } = useParams();
  const {
    saved,
    image,
    imageurl,
    setclear,
    loadproduct,
    category,
    name,
    price,
    status,
    description,
    Categorylist,
    Producttypelist,
    setname,
    filetype,
    audio,
    setprice,
    setdescription,
    setstatus,
    ProductType,
    setProductType,
    setimage,
    setcategory,
    editproduct,
    getCategoryList,
    setcheckbox,
    checkbox,
  } = useProductStore();

  const navigate = useNavigate();
  useEffect(() => {
    if (saved) {
      navigate("../productmanagement");
    }
  }, [saved, loadproduct]);

  function clearFileInput() {
    var fileInput = document.getElementById("myFileInput");
    if (fileInput) {
      fileInput.value = ""; // Clear the value
    }
  }
  // const handleCheckboxChange = (e) => {
  //     const value = e.target.checked ? 1 : 0;
  //     setcheckbox(value);
  //     console.log(value);
  //   };

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Edit Product
          </p>
          {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            editproduct(id);
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="ProductType"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Product Type<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                required
                id="ProductType"
                name="ProductType"
                value={ProductType}
                onChange={(e) => {
                  setProductType(e.target.value);
                  clearFileInput();
                  setclear();
                  getCategoryList(e.target.value);
                }}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" hidden className="">Select Product Type</option> */}
                {Producttypelist &&
                  Producttypelist.map((res, index) => (
                    <option key={index} value={res?.id}>
                      {res?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="Category"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Category<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                id="Category"
                name="Category"
                disabled={!ProductType ? true : false}
                value={category}
                onChange={(e) => {
                  setcategory(e.target.value);
                }}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value="" className="">All</option> */}
                {Categorylist &&
                  Categorylist.map((res, index) => (
                    <option key={index} value={res?.id}>
                      {res?.name}
                    </option>
                  ))}
              </select>
            </div>

            {ProductType !== "" && ProductType != "3" && ProductType != "4" && (
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Name<span className="text-[#f00]">&nbsp;*</span>
                </label>
                <Input
                  name="name"
                  placeholder="Name"
                  id="name"
                  required={
                    ProductType == "1" || ProductType == "2" ? true : false
                  }
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  type="text"
                  autoComplete="off"
                  className="focus:outline-0 w-full text-sm font-medium rounded-md"
                />
              </div>
            )}
            {ProductType != "3" && ProductType != "4" && (
              <div>
                <label
                  htmlFor="Price"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Price<span className="text-[#f00]">&nbsp;*</span>
                </label>
                <Input
                  name="Price"
                  required={
                    ProductType == "1" || ProductType == "2" ? true : false
                  }
                  placeholder="Price"
                  id="Price"
                  min="0.1"
                  title="Value must be greater than 0.1"
                  step="any"
                  value={price}
                  onChange={(e) => {
                    setprice(e.target.value);
                  }}
                  type="number"
                  autoComplete="off"
                  className="focus:outline-0 w-full text-sm font-medium rounded-md"
                />
              </div>
            )}

            <div>
              <label
                htmlFor="Status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                id="Status"
                name="Status"
                value={status}
                onChange={(e) => {
                  setstatus(e.target.value);
                }}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="1">Active</option>
                <option value="0">Inactive </option>
              </select>
            </div>
            <div>
              <label
                htmlFor="Description"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Description<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <textarea
                name="Description"
                placeholder="Description"
                id="Description"
                value={description}
                onChange={(e) => {
                  setdescription(e.target.value);
                }}
                autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              ></textarea>
            </div>

            {ProductType && (
              <div>
                {/* {console.log(filetype)} */}
                <label
                  htmlFor="File"
                  className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
                >
                  Image<span className="text-[#f00]">&nbsp;*</span>
                </label>
                <div className=" grid grid-cols-3">
                  <Input
                    name="image"
                    placeholder="Image"
                    id="myFileInput"
                    accept={ProductType == "2" ? "audio/*" : "image/*"}
                    onChange={(e) => {
                      setimage(
                        e.target.files,
                        e.target.files[0].name?.split(".").pop().toLowerCase()
                      );
                    }}
                    type="file"
                    autoComplete="off"
                    className="focus:outline-0 w-full text-sm font-medium rounded-md col-span-2"
                  />
                  {filetype !== "mp3" ? (
                    <img className="w-full" src={imageurl} />
                  ) : (
                    <audio controls src={audio}></audio>
                  )}
                </div>
              </div>
            )}

            {/* <div>
                            <label htmlFor="checkbox" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Popular Product</label>
                            <div className=" grid grid-cols-3">
                            <input
                                name="checkbox"
                                id="checkbox"
                                // defaultValue=""
                                defaultChecked={checkbox === 1 }
                                onChange={handleCheckboxChange}
                                checked={checkbox === 1}
                                type="checkbox"
                                autoComplete="off"
                                className="focus:outline-0 w-5 h-5 text-sm font-medium rounded-md "
                            />
                            </div>
                        </div> */}
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="/productmanagement"
              className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            <button
              type="submit"
              className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"
            >
              <MdOutlineCheck className="mr-1" />
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
