import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useReportedUserStore, ReportedUserStore, ReportedUserStoreContext } from "../store/ReportedUserStore";
import { observer } from "mobx-react";

// const Filter = observer(() => {
//     const store = new ReportedUserStore({
//         isloading:true,
//       });
//     return (
//         <ReportedUserStoreContext.Provider value={store}>
//             <Screen />
//         </ReportedUserStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer(({emptyinput}) => {
    const {
        setreported_by,
        setreported_for,
        setstatus,
        setfromdate,
        settodate,
        getFilterReported,
        getReported,
        setreporttype
      }= useReportedUserStore()

      const Reset=()=>{
        setreported_by("")
        setreported_for("")
        setstatus("")
        setfromdate("")
        settodate("")
        setreporttype("")
    }

    useEffect(() => {
        setreported_by("")
        setreported_for("")
        setstatus("")
        setfromdate("")
        settodate("")
        setreporttype("")
    },[emptyinput])

    return (
        <>

            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {getFilterReported(1); e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                            <label htmlFor="fromDate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">From Date</label>
                            <Input
                                name="fromDate"
                                placeholder=" From Date"
                                id="fromDate"
                                type="date"
                                onChange={(e)=> {setfromdate(e.target.value)}}
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="toDate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">To Date</label>
                            <Input
                                name="toDate"
                                placeholder=" To Date"
                                id="toDate"
                                onChange={(e)=> {settodate(e.target.value)}}
                                type="date"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="ReportedBy" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Reported By</label>
                            <Input
                                name="ReportedBy"
                                placeholder="User Reported By"
                                id="ReportedBy"
                                onChange={(e)=> {setreported_by(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="ReportedFor" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Reported For</label>
                            <Input
                                name="ReportedFor"
                                placeholder="User Reported For"
                                id="ReportedFor"
                                onChange={(e)=> {setreported_for(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="report type" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Report Type</label>
                            <select
                                id="report type"
                                name="report type"
                                onChange={(e)=> {setreporttype(e.target.value)}}
                                defaultValue=""
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                <option value="User">User</option>
                                <option value="Message">Message</option>
                            </select>
                        </div>
                        {/* <div>
                            <label htmlFor="CustomerEmail" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Customer Email</label>
                            <Input
                                name="CustomerEmail"
                                placeholder="Customer Email"
                                id="CustomerEmail"
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div> */}
                        <div>
                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                            <select
                                id="Status"
                                name="Status"
                                onChange={(e)=> {setstatus(e.target.value)}}
                                defaultValue=""
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Block">Block</option>
                                <option value="Unblock">Unblock</option>
                            </select>
                        </div>
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button type="reset" onClick={() => {getReported() ;Reset() }} className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})
export default Filter;