import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Home/Dashboard';
import Profile from './Profile/Profile';
import { PageNotFound } from './PageNotFound/PageNoteFound';
import Setting from './Setting/Setting';
import UserManagement from './UserManagement/UserManagement';
import EditUserManagement from './UserManagement/EditUserManagement';
import CategoryManagement from './CategoryManagement/CategoryManagement';
import EditCategoryManagement from './CategoryManagement/EditCategoryManagement';
import AddCategoryManagement from './CategoryManagement/AddCategoryManagement';
import ProductManagement from './ProductManagement/ProductManagement';
import AddProductManagement from './ProductManagement/AddProductManagement';
import EditProductManagement from './ProductManagement/EditProductManagement';
import PaymentManagement from './PaymentManagement/PaymentManagement';
import ReviewManagement from './ReviewsManagement/ReviewManagement';
import ReportedUserManagement from './ReporteUserManagement/ReportedUserManagement';
import OrderManagement from './OrderManagement/OrderManagement';
import CategoryProductManagement from './ProductManagement/CategoryProducts';
import EditOrderManagement from './OrderManagement/EditOrderManagement';
import CoupenManagement from './CoupenManagement/CoupenManagement';
import AddCoupenManagement from './CoupenManagement/AddCoupenManagement/AddCoupenMangament';
import EditCoupenManagement from './CoupenManagement/EditCoupenManagement/EditCoupenManagement';
import CmsManagement from './CmsMangement/CmsManagement';
import EditCmsManagement from './CmsMangement/CmsEdit/CmsEdit';
import AddSetting from './Setting/AddSetting/AddSettings';
import NotificationList from './Notifications/NotificationList';
import WithdrawalManagement from './WithdrawalManagement/WithdrawalList';
import ContactManagement from './ContactManagement/ContactList';
import DeletedUser from './UserManagement/DeletedUser';
import MyearningManagement from './MyearningManagement/MyearningManagement';
import FaqManagement from './FaqManagement/FaqManagement';
import EditFaqManagement from './FaqManagement/FaqEdit/FaqEdit';
import AddFaqManagement from './FaqManagement/FaqAdd/FaqAdd';
import GroupManagement from './GroupManagement/GroupManagement';
import ChannelManagement from './ChannelManagement/ChannelManagement';
import PublicChannelManagement from "./PublicChannelManagement/PublicChannelManagement";
import ReportedChannelManagement from "./ReportChannelManagement/ReportedChannelManagement";
import SubscriptionManagement from "./SubscriptionManagement/SubscriptionManagement";
import UserSubscriptionList from "./SubscriptionManagement/UserSubscriptionList";


export const pages= [
    {path:"/", component: <Dashboard/> },
    {path:"/notifications", component: <NotificationList/> },
    {path:"/profile", component: <Profile/> },
    {path:"/usermanagement", component: <UserManagement/> },
    {path:"/groupmanagement", component: <GroupManagement/>},
    {path:"/usermanagement/deleteuser", component: <DeletedUser/> },
    {path:"/usermanagement/:id", component: <EditUserManagement/> },
    {path:"/categorymanagement", component: <CategoryManagement/> },
    {path:"/categorymanagement/addcategory", component: <AddCategoryManagement/> },
    {path:"/categorymanagement/:id", component: <EditCategoryManagement/> },
    {path:"/productmanagement", component: <ProductManagement/> },
    {path:"/productmanagement/category/:id", component: <CategoryProductManagement/> },
    {path:"/productmanagement/addproduct", component: <AddProductManagement/> },
    {path:"/productmanagement/:id", component: <EditProductManagement/> },
    {path:"/paymentsmanagement", component: <PaymentManagement/> },
    {path:"/myearningmanagement", component: <MyearningManagement/> },
    {path:"/withdrawalmanagement", component: <WithdrawalManagement/> },
    {path:"/contactmanagement", component: <ContactManagement/> },
    {path:"/ordermanagement", component: <OrderManagement/> },
    {path:"/ordermanagement/:id", component: <EditOrderManagement/> },
    {path:"/reviewsmanagement", component: <ReviewManagement/> },
    {path:"/reporteduser", component: <ReportedUserManagement/> },
    {path:"/couponmanagement", component: <CoupenManagement/> },
    {path:"/couponmanagement/addcoupen", component: <AddCoupenManagement/> },
    {path:"/couponmanagement/:id", component: <EditCoupenManagement/> },
    {path:"/cmsmanagement", component: <CmsManagement/> },
    {path:"/cmsmanagement/:id", component: <EditCmsManagement/> },
    {path:"/faqmanagement", component: <FaqManagement/> },
    {path:"/faqmanagement/:id", component: <EditFaqManagement/> },
    {path:"/faqmanagement/addfaq", component: <AddFaqManagement/> },
    {path:"/setting", component: <Setting/> },
    {path:"/setting/addsetting", component: <AddSetting/> },
    {path:"/channelmanagement", component: <ChannelManagement/> },
    { path: "/publicchannelmanagement", component: <PublicChannelManagement /> },
    { path: "/reportedchannel", component: <ReportedChannelManagement /> },
    { path: "/subscriptionsmanagement", component: <SubscriptionManagement /> },
    {path: "/subscriptionsmanagement/orders/:id", component: <UserSubscriptionList /> },
]

const Routess = () => {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
       {pages.map(({component, path})=>(
        <Route key={path}
            element={component}
            path={path}
        />
       ))}
       <Route
        path="*"
        element={<PageNotFound/>}
      />
    </Routes>
  
  )
}

export default Routess