import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { usePaymentStore, PaymentStore, PaymentStoreContext } from "../store/PaymentStore";
import { observer } from "mobx-react";

// const Filter = observer(() => {
//     const store = new PaymentStore({
//         isloading:true,
//       });
//     return (
//         <PaymentStoreContext.Provider value={store}>
//             <Screen />
//         </PaymentStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer(({emptyinput}) => {

    const {
        getPayments,
        NofilterwithPagination,
        getFilterPayments,
        settransactionid,
        setcustomeremail,
        setcustomername,
        setfromdate,
        setorderid,
        setstatus,
        settodate,
        loadTableData,
        setfiltertrue,
        settransactiontype    
    }
    = usePaymentStore()

    const Reset=()=>{
        settransactionid("")
        setcustomeremail("")
        setcustomername("")
        setfromdate("")
        setorderid("")
        setstatus("")
        settodate("")
        settransactiontype("")
        loadTableData(1)
        setfiltertrue(false)
    }

    // useEffect(() => {
    //     getPayments();
    // }, [])

    useEffect(() => {
        settransactionid("")
        setcustomeremail("")
        setcustomername("")
        setfromdate("")
        setorderid("")
        setstatus("")
        settodate("")
        settransactiontype("")
    },[emptyinput])
    
    return (
        <>

            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {NofilterwithPagination(false); getFilterPayments(1); e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label htmlFor="TransactionId" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Transaction ID</label>
                            <Input
                                name="TransactionId"
                                placeholder="Transaction ID"
                                id="TransactionId"
                                onChange={(e)=> {settransactionid(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        {/* <div>
                            <label htmlFor="orderId" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Order ID</label>
                            <Input
                                name="orderId"
                                placeholder="Order ID"
                                id="orderId"
                                onChange={(e)=> {setorderid(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div> */}
                        <div>
                            <label htmlFor="fromDate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">From Date</label>
                            <Input
                                name="fromDate"
                                placeholder=" From Date"
                                id="fromDate"
                                onChange={(e)=> {setfromdate(e.target.value)}}
                                type="date"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="toDate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">To Date</label>
                            <Input
                                name="toDate"
                                placeholder=" To Date"
                                id="toDate"
                                onChange={(e)=> {settodate(e.target.value)}}
                                type="date"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="CustomerName" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Customer Name</label>
                            <Input
                                name="CustomerName"
                                placeholder="Customer Name"
                                id="CustomerName"
                                onChange={(e)=> {setcustomername(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                 
                        <div>
                            <label htmlFor="CustomerEmail" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Customer Email</label>
                            <Input
                                name="CustomerEmail"
                                placeholder="Customer Email"
                                id="CustomerEmail"
                                onChange={(e)=> {setcustomeremail(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        {/* <div>
                            <label htmlFor="transaction" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Transaction Type</label>
                            <select
                                id="transaction"
                                name="transaction"
                                defaultValue=""
                                onChange={(e)=> {settransactiontype(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                <option value="Debit">Debit</option>
                                <option value="Credit">Credit</option>
                               
                            </select>
                        </div> */}
                        <div>
                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status</label>
                            <select
                                id="Status"
                                name="Status"
                                defaultValue=""
                                onChange={(e)=> {setstatus(e.target.value)}}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" className="">All</option>
                                <option value="Failed">Failed</option>
                                <option value="Processing">Processing</option>
                                <option value="Pending">Pending</option>
                                <option value="Completed">Completed</option>
                            </select>
                        </div>
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button type="reset" onClick={() => {getPayments();Reset()  }} className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button  type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})
export default Filter;