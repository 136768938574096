import { observer } from "mobx-react";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import {
  usePaymentStore,
  PaymentStore,
  PaymentStoreContext,
} from "./store/PaymentStore";
import Filter from "./PaymentFilter/paymentfilter";
import { BiDollarCircle } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentManagement = () => {
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page") || 1;

  const store = new PaymentStore({
    isloading: true,
    pagginationcount: currentPage,
  });
  return (
    <PaymentStoreContext.Provider value={store}>
      <Screen />
    </PaymentStoreContext.Provider>
  );
};

export default PaymentManagement;

const Screen = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [emptyinput,setemptyinput] = useState(false)
  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  useEffect(() => {
    setActivePage(searchParamPage ?? 1);
    setIndexPage(searchParamPage ?? 1);
  }, [currentPage]);

  const {
    loadTableData,
    setActivePage,
    setIndexPage,
    getFilterPayments,
    NofilterwithPagination,
    getPayments,
    indexPage,
    filtertrue,
    filterVisibility,
    activePage,
    perPage,
    Count,
    Payments,
    loadPayments,
    nolist,
  } = usePaymentStore();

  /**
   * useEffect to get the category list with currrentPage from url params
   */
  useEffect(() => {
    if (searchParamPage) {
      const page = Number(searchParamPage ?? 1);
      setCurrentPage(page);
      setIndexPage(page);
    }
  }, [searchParamPage]);

  const handlePageChange = (pageNumber) => {
    NofilterwithPagination(true);
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    console.log(" setfiltertrue(false)", filtertrue);
    if (!filtertrue) {
      getFilterPayments(pageNumber);
      setIndexPage(pageNumber);
      setActivePage(pageNumber);
    } else {
      if (filterVisibility === true) {
        getPayments(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
    }

    /**
     * if selected page is not 1 then navigate to the selected page adn ad dpageNumber to url
     */
    if (Number(pageNumber) + 1 !== 1) {
      navigate(`?page=${Number(pageNumber)}`);
      setActivePage(Number(pageNumber) + 1);
    } else {
      navigate("");
    }
  };
  //  useEffect(() => {
  //   loadTableData(1);
  // }, []);
  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Wallet Topup Report
          </p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
      </div>
      <Filter emptyinput={emptyinput}/>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Transaction ID</th>
                {/* <th>Order Id</th> */}
                <th>Amount</th>
                <th>Customer</th>
                {/* <th>Customer Email</th> */}
                <th>Date</th>
                {/* <th>Transaction Type</th>  */}
                <th>Payment Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody id="tablescroll" className="text-sm relative">
              {loadPayments && (
                <>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {Payments &&
                Payments.map((res, index) => (
                  <tr key={index}>
                    <td> {perPage * (indexPage - 1) + (index + 1)}</td>
                    <td className=" capitalize">#{res?.transaction_id}</td>
                    {/* <td className=" capitalize">{res?.order?.order_number ? `#${res?.order?.order_number}`  :"- -"}</td> */}
                    <td className=" capitalize">${res?.amount}</td>
                    <td className=" capitalize">
                      <span className=" font-bold">
                        {res?.customer?.first_name +
                          " " +
                          res?.customer?.last_name}
                      </span>{" "}
                      <p className="capitalize text-xs">
                        {res?.customer?.email}
                      </p>
                    </td>
                    {/* <td className=" capitalize"></td> */}
                    <td className=" capitalize">
                      <Moment format="MM/DD/YYYY">
                        {res.transactons_date ?? "N/A"}
                      </Moment>
                    </td>
                    {/* <td className=" capitalize">{res?.transaction_type ?? "N/A"}</td> */}
                    <th>
                      <label
                        // title="Change Status"
                        // htmlFor="my-modal-5"
                        className={`btn capitalize ${
                          res.transacton_status === "Completed"
                            ? "bg-green hover:bg-green"
                            : "bg-warning hover:bg-warning"
                        }  text-[#000] border-hidden  btn-xs`}
                        // onClick={(e) => {
                        // }}
                      >
                        {res.transacton_status}
                      </label>
                    </th>
                    {/* <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/paymentmanagement/${1}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td> */}
                  </tr>
                ))}
              {nolist && (
                <tr>
                  <td colSpan={8} className="my-10 text-center">
                    <BiDollarCircle className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Payments Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            activePage={activePage}
            containerClassName={"pagination"}
            itemsCountPerPage={perPage}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />
          {/* status change model start */}
          {/* <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
          {/* status change model end */}
        </div>
      </div>
    </>
  );
});
