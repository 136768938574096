import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson, RxPlus } from 'react-icons/rx';
import { WithdrawalStore, useWithdawalStore , WithdrawalStoreContext } from "./Store/WithdrawalStore";
// import Filter from "./PaymentFilter/paymentfilter";
import Filter from "./WithdrawalFilter/WithdrawalFilter";
import { BiDetail, BiDollarCircle } from "react-icons/bi";

const WithdrawalManagement = () => {
    const store = new WithdrawalStore({
      isloading:true,
    });
    return(
      <WithdrawalStoreContext.Provider value={store}>
        <Screen/>
      </WithdrawalStoreContext.Provider>
    )
  }
  
  export default WithdrawalManagement;

  const Screen = observer(()=>{
    const {
    Withdrawal,
    loadWithdrawaL,
    nolist,
    Statuspopup,
    UserLoader,
    statusChange,
    setstatus,
    setstatusVoidpopup,
    setBankDetails,
    BankDetails
     
    }= useWithdawalStore()
    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">Withdrawal Report</p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        
       </div>
       <Filter/>
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                <th>User</th>
                <th>Transaction ID</th>
                <th>withdrawn Amount</th>
                <th>Wallet Balance</th>
                <th>Date</th>
                {/* <th>Status</th> */}
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">

            {loadWithdrawaL && 
              <>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

            {
                Withdrawal && Withdrawal.map((res,index) => (
                  <tr key={index}>
                <td>{index+1}</td>
                <td className=" capitalize">
                <div className="flex items-center space-x-3">
                    {/* <div className="avatar">
                    <div className="mask mask-squircle w-12 h-12">
                        <img src={userimage} alt="Avatar Tailwind CSS Component" />
                    </div>
                    </div> */}
                    <div>
                    <div className="font-bold capitalize">{(res?.user.first_name??"-")+" "+(res?.user.last_name??"-")}</div>
                    {/* <div className="font-bold">Lname</div> */}
                  <div className="text-sm ">{res?.user.email}</div>
                    </div>
                    </div>
                    
                </td>
                <td className=" capitalize">{res?.transaction_id ? `#${res.transaction_id}` : "- -"}</td>
                <td className=" capitalize">${res?.amount}</td>
                <td className=" capitalize">${res?.user?.wallet?.balance}</td>
                <td className=" capitalize"> {<Moment format="MM/DD/YYYY">{res?.created_at ?? "N/A"}</Moment> }</td>
                {/* <th>
                <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${res?.status === "Pending" ? "bg-warning hover:bg-warning" :  "bg-green hover:bg-green"}  text-[#000] border-hidden  btn-xs`}
                      
                      onClick={(e) => {
                        res.status ===  "Pending" &&
                        setstatusVoidpopup(true);
                        setstatus(res?.id)
                        // getNewstatus(data?.isActive);
                      }}
                    >
                      {res?.status === "Pending" ? "Pending" : "Completed"}
                    </label>

                  </th> */}
                {/* <td className=" text-base">
                <label
                      title="Bank Details"
                      htmlFor="BankDetails"
                      className={`btn capitalize text-[white] border-hidden  btn-xs`}
                      onClick={(e) => 
                        setBankDetails(res)
                      }
                  
                    >
                                             <BiDetail />

                    </label>
                  
                  </td> */}
                </tr>
             ))
            }
              {nolist && 
              <tr>
                <td colSpan={6} className="my-10 text-center">
                  <BiDollarCircle className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Withdrawal History Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
            {/* status change model start */}

            {Statuspopup && 
        <>
         <input type="checkbox" id="my-modal-5" className="modal-toggle" />

<div className="modal">
  <div className="modal-box relative">
    <label
     htmlFor="my-modal-5"
      className="btn btn-sm btn-circle absolute right-2 top-2"
      onClick={()=>setstatusVoidpopup(false)}
    >
      ✕
    </label>
    <h3 className="text-lg font-bold text-center">
      Are you sure you want to change Status?
    </h3>
    <div className="modal-action justify-center">
      {!UserLoader ?        <label
      htmlFor="my-modal-5"
        className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
        onClick={() => statusChange()}
      >
        Yes
      </label> :   
      <button
                  // type="submit"
                  disabled
                  className="flex items-center bg-green px-3 mx-5 rounded-md"
                  // onClick={() => {
                  //   PostMatchscore();
                  // }}
                >
                  Yes..
                  <svg
                    className="animate-spin mr-1 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </button> }

                <label
  onClick={()=>setstatusVoidpopup(false)}
    // htmlFor="voidstatus"
    htmlFor="my-modal-5"
    className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]"
  >
    No
  </label>
    </div>
  </div>
</div>
        </>
       
}
<>
         <input type="checkbox" id="BankDetails" className="modal-toggle" />

<div className="modal">
  <div className="modal-box relative">
    <label
     htmlFor="BankDetails"
      className="btn btn-sm btn-circle absolute right-2 top-2"
      onClick={()=>setstatusVoidpopup(false)}
    >
      ✕
    </label>
    <span className="flex justify-center font-bold text-lg text-[#2f5542]">Bank Details</span>
    <div className="">
<p className="font-bold">Bank Name : {BankDetails?.branch_name}</p>
<p className="font-bold">Ifsc Code : {BankDetails?.ifsc_code}</p>

<p className="font-bold">Account Holder Name : {BankDetails?.account_holder_name} </p>

<p className="font-bold">Account No. : {BankDetails?.account_number} </p>


    </div>
    
             
               
               
          
 

  </div>
</div>
        </>

          {/* status change model end */}
        {/* status change model start */}
        {/* <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div> */}
          {/* status change model end */}
        </div>
    </div>
       </>
    )
  })