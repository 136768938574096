/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";
import {
  MdOutlineClose,
  MdOutlineCheck,
  MdOutlineRefresh,
  MdOutlineSearch,
} from "react-icons/md";
import { Input, Button } from "react-daisyui";
import {
  useOrderStore,
  OrderStore,
  OrderStoreContext,
} from "./store/OrderStore";
import { observer } from "mobx-react";
import { BiBullseye, BiDetail } from "react-icons/bi";
import { BsFillEyeFill } from "react-icons/bs";

const EditOrderManagement = observer(() => {
  const { id } = useParams();
  const store = new OrderStore({
    isloading: false,
    editloading: true,
    id: id,
  });
  return (
    <OrderStoreContext.Provider value={store}>
      <Screen />
    </OrderStoreContext.Provider>
  );
});

export default EditOrderManagement;

const Screen = observer(() => {
  const { orderone, loadOrder, getProductList, productlist } = useOrderStore();

  const goBack = () => {
    window.history.back();
  };
  
  return (
    <>
      <div className=" mb-4 flex justify-between">
        <p className=" text-2xl ml-6 font-semibold text-moredarkgreen">
          Order Details #{orderone?.order_number}
        </p>
        <div>
          <a
            className="btn btn-sm bg-darkgreen mr-5 text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
            href={`${orderone?.invoice_url}`}
            target="_blank"
            rel="noreferrer"
          >
            Invoice Details
          </a>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
          >
            Back
          </Link>
        </div>
      </div>

      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 md:gap-8 gap-4">
          <div className=" grid grid-cols-2 gap-4 h-fit">
            <p>Customer Name</p>
            <p className=" font-bold capitalize">
              {orderone?.customer?.first_name +
                " " +
                orderone?.customer?.last_name}
            </p>

            <p>Order ID</p>
            <p className=" font-bold capitalize">#{orderone?.order_number}</p>

            <p>Order Amount</p>
            <p className=" font-bold capitalize">
              ${orderone?.final_order_amount}
            </p>

            <p>Discount Amount</p>
            <p className=" font-bold capitalize">${orderone?.discounted_amt}</p>
          </div>
          <div className=" grid grid-cols-2 gap-4 h-fit">
            <p>Order Date</p>
            <p className=" font-bold capitalize">
              <Moment format="MM/DD/YYYY">
                {orderone.order_date_time ?? "N/A"}
              </Moment>
            </p>

            <p>Quantity</p>
            <p className=" font-bold capitalize">{orderone?.quantity}</p>

            <p>Order Status</p>
            <p className=" font-bold capitalize">{orderone?.order_status}</p>

            <p>Payment Status</p>
            <p className=" font-bold capitalize">{orderone?.payment_status}</p>
          </div>
        </div>
        <div className=" mb-9 mt-5 flex justify-between">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Billing Details
          </p>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 md:gap-8 gap-4">
          <div className=" grid grid-cols-2 gap-4 h-fit">
            <p>Billing Name</p>
            <p className=" font-bold capitalize">
              {orderone?.billing_name ? orderone?.billing_name : "-"}
            </p>
            <p>Billing Address</p>
            <p className=" font-bold capitalize">
              {orderone?.address ? orderone?.address : "-"}
            </p>
          </div>
          <div className=" grid grid-cols-2 gap-4 h-fit">
            <p>Billing Email</p>
            <p className=" font-bold capitalize">
              {orderone?.billing_email ? orderone?.billing_email : "-"}
            </p>
            <p>Postal Code</p>
            <p className=" font-bold capitalize">
              {orderone?.postal_code ? orderone?.postal_code : "-"}
            </p>
          </div>
        </div>
        <div className=" mb-9 mt-5 flex justify-between">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Product Details
          </p>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 md:gap-8 gap-4">
          <div className=" grid grid-cols-2 gap-4 h-fit">
            <p>Product Type</p>
            <p className=" font-bold capitalize">
              {orderone?.type?.name ? orderone?.type?.name : "-"}
            </p>

            {/* <p>Billing Address</p>
                        <p className=" font-bold capitalize">{orderone?.address ? orderone?.address : "-" }</p> */}
          </div>
          <div className=" grid grid-cols-2 gap-4 h-fit">
            <p>Product Name</p>
            <p className=" font-bold capitalize">
              {orderone?.product != null ? (
                <div
                  className={`flex ${
                    orderone?.type?.id == "2" ? "flex-col" : "flex-row"
                  }`}
                >
                  {" "}
                  <div className="mr-5"> {orderone?.product?.name} </div>{" "}
                  <div className="flex justify-between">
                    {orderone?.type?.id == "2" ? (
                      <audio controls src={orderone?.product?.image}></audio>
                    ) : (
                      <label
                        title="Bank Details"
                        htmlFor="BankDetails"
                        className="btn btn-xs bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
                        onClick={(e) => getProductList(orderone?.id)}
                      >
                        <BsFillEyeFill
                          title="View Bill"
                          className="cursor-pointer"
                        />
                      </label>
                    )}
                  </div>{" "}
                </div>
              ) : (
                <div className="flex flex-row">
                  {" "}
                  <div className="mr-5"> {orderone?.category?.name} </div>{" "}
                  <div className="flex justify-between">
                    <label
                      title="Bank Details"
                      htmlFor="BankDetails"
                      className="btn btn-xs bg-darkgreen  text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
                      onClick={(e) => getProductList(orderone?.id)}
                    >
                      <BsFillEyeFill
                        title="View Product"
                        className=" cursor-pointer"
                      />
                    </label>
                  </div>{" "}
                </div>
              )}
            </p>
          </div>
        </div>

        <div className="card bg-base-100 shadow-md p-6 mt-8">
          <div className="overflow-x-auto w-full">
            {loadOrder && (
              <>
                <tr>
                  <td colSpan={6} className="animate-pulse py-6"></td>
                </tr>
                <tr>
                  <td colSpan={6} className="animate-pulse py-6"></td>
                </tr>
                <tr>
                  <td colSpan={6} className="animate-pulse py-6"></td>
                </tr>
                <tr>
                  <td colSpan={6} className="animate-pulse py-6"></td>
                </tr>
                <tr>
                  <td colSpan={6} className="animate-pulse py-6"></td>
                </tr>
              </>
            )}

            <>
              <input
                type="checkbox"
                id="BankDetails"
                className="modal-toggle"
              />

              <div className="modal">
                <div className="modal-box relative">
                  <label
                    htmlFor="BankDetails"
                    className="btn btn-sm btn-circle absolute right-2 top-2"
                  >
                    ✕
                  </label>
                  <span className="flex justify-center font-bold text-lg text-[#2f5542]">
                    {orderone?.type?.name} of{" "}
                    {orderone?.product?.name || orderone?.category?.name}
                  </span>
                  <div className="grid grid-cols-3 gap-4">
                    {productlist &&
                      productlist?.map((res, index) => (
                        <div
                          key={index}
                          className="flex justify-center items-center"
                        >
                          <img
                            className="w-6/12"
                            src={res.image}
                            alt={`Product ${index}`}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
            {/* <img src={res?.product?.image} className="w-10"/> */}

            {/* {nolist && 
                        <tr>
                            <td colSpan={5} className="my-10 text-center">
                            <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                            <p className=" font-semibold text-lg">No Order Found</p>
                            </td>
                        </tr>
                        } */}
          </div>
        </div>
      </div>
    </>
  );
});
