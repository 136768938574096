import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import {
  MdOutlineClose,
  MdOutlineCheck,
  MdOutlineRefresh,
  MdOutlineSearch,
} from "react-icons/md";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useUserStore, UserStore, UserStoreContext } from "./store/UserStore";

const EditUserManagement = observer(() => {
  const { id } = useParams();
  return (
    <UserStoreContext.Provider
      value={
        new UserStore({
          isloading: false,
          editloading: true,
          id: id,
        })
      }
    >
      <Screen />
    </UserStoreContext.Provider>
  );
});

export default EditUserManagement;

const Screen = observer(() => {
  const {
    loaduser,
    saved,
    singleUser,
    first_name,
    last_name,
    phone_number,
    email,
    gender,
    address,
    street,
    city,
    state,
    postal_code,
    country,
    status,
    Countrylist,
    Statelist,
    citylist,
    Display_name,
    getStateList,
    blankCityState,
    setFirst_name,
    setDisplay_name,
    setlast_name,
    setphone_number,
    getCityList,
    setemail,
    setgender,
    setaddress,
    setstreet,
    setcity,
    setstate,
    setpostal_code,
    setcountry,
    setstatus,
    edituser,
  } = useUserStore();

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (saved) {
      navigate("../usermanagement");
    }
  }, [saved, loaduser]);

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Edit User
          </p>
          {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
        </div>
        <div>
          <Link
            onClick={goBack}
            className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen "
          >
            Back
          </Link>
        </div>
      </div>

      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            edituser(id);
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
            <div>
              <label
                htmlFor="display_name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Display Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="display_name"
                required
                readOnly
                // onChange={(e)=>setDisplay_name(e.target.value)}
                value={Display_name}
                placeholder="Display Name"
                id="display_name"
                type="text"
                autoComplete="off"
                // onChange={(e)=> {setfirst_name(e.target.value)}}

                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                First Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                name="first_name"
                required
                onChange={(e) => setFirst_name(e.target.value)}
                defaultValue={first_name}
                placeholder="First Name"
                id="first_name"
                type="text"
                autoComplete="off"
                // onChange={(e)=> {setfirst_name(e.target.value)}}

                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Last Name<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                required
                name="last_name"
                placeholder="Last Name"
                id="last_name"
                type="text"
                autoComplete="off"
                value={last_name}
                onChange={(e) => {
                  setlast_name(e.target.value);
                }}
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Email<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                required
                name="email"
                placeholder="Email"
                id="email"
                type="email"
                autoComplete="off"
                value={email}
                readOnly
                // onChange={(e)=> {setemail(e.target.value)}}
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="gender"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Gender<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                required
                id="gender"
                name="gender"
                value={gender}
                onChange={(e) => {
                  setgender(e.target.value);
                }}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" className=" hidden">
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="phone_number"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Mobile<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <PhoneInput
                name="phone_number"
                minLength={15}
                maxLength={15}
                required
                readOnly
                disabled
                // onChange={setphone_number}
                value={phone_number}
                // value={`+1${usermobile ?? ""}`}
                // defaultCountry="US"
                placeholder="Mobile"
                id="phone_number"
                // autoComplete="off"
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="country"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Country<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                required
                id="country"
                name="country"
                value={country ? country : ""}
                onChange={(e) => {
                  setcountry(e.target.value);
                  getStateList(e.target.value);
                  blankCityState();
                }}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value={""} className="">
                  Select Country
                </option>
                {Countrylist &&
                  Countrylist.map((res, index) => (
                    <option key={index} value={res?.id} className="">
                      {res?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                State
                <span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                required={Statelist.length == "0" ? false : true}
                id="state"
                name="state"
                value={state}
                onChange={(e) => {
                  setcity("");
                  setstate(e.target.value);
                  getCityList(e.target.value);
                }}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" hidden className="">
                  Select State
                </option>
                {Statelist &&
                  Statelist.map((res, index) => (
                    <option key={index} value={res?.id} className="">
                      {res?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                City<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                id="city"
                required={citylist.length == "0" ? false : true}
                name="city"
                value={city}
                onChange={(e) => {
                  setcity(e.target.value);
                }}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                <option value="" hidden className="">
                  Select City
                </option>
                {citylist &&
                  citylist.map((res, index) => (
                    <option key={index} value={res?.id} className="">
                      {res?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Address
                <span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                required
                name="address"
                placeholder="Address"
                id="address"
                type="text"
                autoComplete="off"
                value={address}
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>
            <div>
              <label
                htmlFor="street"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Street
                <span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                required
                name="street"
                placeholder="Street"
                id="street"
                type="text"
                autoComplete="off"
                value={street}
                onChange={(e) => {
                  setstreet(e.target.value);
                }}
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>

            <div>
              <label
                htmlFor="postal_code"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Zipcode
                <span className="text-[#f00]">&nbsp;*</span>
              </label>
              <input
                required
                minLength={5}
                maxLength={5}
                name="postal_code"
                placeholder="Zipcode"
                id="postal_code"
                type="text"
                autoComplete="off"
                value={postal_code}
                onChange={(e) => {
                  setpostal_code(e.target.value);
                }}
                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
              />
            </div>

            <div>
              <label
                htmlFor="status"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Status<span className="text-[#f00]">&nbsp;*</span>
              </label>
              <select
                required
                id="status"
                name="status"
                value={status}
                onChange={(e) => {
                  setstatus(e.target.value);
                }}
                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}
              >
                {/* <option value={status} className=" hidden">{status ? "Active" : "Inactive"}</option> */}
                <option value="1">Active</option>
                <option value="0">Inactive </option>
              </select>
            </div>
          </div>
          <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
            <Link
              to="../usermanagement"
              className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "
            >
              <MdOutlineClose className="mr-1" />
              Cancel
            </Link>
            <button
              type="submit"
              className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"
            >
              <MdOutlineCheck className="mr-1" />
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
});
